import React, { useState } from "react";
import Sidebar from "../component/Sidebar";
import { useNavigate } from "react-router-dom";
import ProductService from "../services/ProductService";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

function SearchProduct() {
	const [searchQuery, setSearchQuery] = useState("");
	const [err, setErr] = useState();
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const listProduct = async (search, limit) => {
		setLoading(true);
		try {
			const products = await ProductService.getProducts(search, limit, "");
			if (products?.status && products?.data.value.length > 0) {
				const foundData = products?.data?.value.at(0);
				navigate(`/product-detail/${foundData?.id}`);
				setLoading(false);
			} else {
				setErr("Product Not Found");
				setLoading(false);
			}
		} catch (error) {
			toast.error(error.message);
			setLoading(false);
		}
	};
	return (
		<div className="container__wrapper">
			<Sidebar />
			<div className="section__container">
				<div className="header__title">
					<h3>Product Search</h3>
				</div>
				<div
					style={{
						minHeight: "60vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<div
						className="search__input"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
						}}
					>
						<div className="col-md-8" style={{ position: "relative" }}>
							<input
								className="form-control"
								style={{
									borderRadius: "55px",
									height: "6rem",
									fontSize: "2rem",
									padding: "0 2rem",
								}}
								type="text"
								placeholder="Enter Serial Number"
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
							<button
								className="btn btn-secondary"
								style={{
									position: "absolute",
									right: "2rem",
									top: "50%",
									transform: "translateY(-50%)",
									height: "70%",
									borderRadius: "55px",
									fontSize: "1.2rem",
									width: "20%",
								}}
								onClick={() => listProduct(searchQuery, 1)}
							>
								{loading ? (
									<ClipLoader loading={true} color="white" size={40} />
								) : (
									"Search"
								)}
							</button>
						</div>
					</div>
					{err && (
						<div
							style={{ color: "red", fontSize: "1.2rem", marginTop: "1rem" }}
						>
							{err}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default SearchProduct;

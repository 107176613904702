import React, { useEffect, useState } from "react";
import ProductRepairModal from "../component/ProductRepairModal";
import Sidebar from "../component/Sidebar";
import { Table } from "react-bootstrap";
import ProductService from "../services/ProductService";
import { NoData } from "../component/miniComponent/NoData";
import { GiAutoRepair } from "react-icons/gi";
import { TableSkeleton } from "../component/miniComponent/TableSkeleton";
import { TableHead } from "../component/miniComponent/TableHead";
import { TableData } from "../component/miniComponent/TableData";
import { toast } from "react-toastify";
import { FilterBox } from "../component/miniComponent/FilterBox";
import ExportModal from "../component/ExportModal";
import { FiDownload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import RepairModal from "../component/RepairModal";
import UserService from "../services/UserService";

function Repair() {
	const [repair, setRepair] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [limit, setLimit] = useState(20);
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [exportModalShow, setExportModalShow] = useState(false);
	const [engineersList, setEngineersList] = useState([]);
	const [isEdit, setIsEdit] = useState(false);

	const userRole = localStorage.getItem("role");

	const listRepairs = async (search, limit, status) => {
		setLoading(true);
		try {
			const repairs = await ProductService.getProductRepairs(
				"",
				search,
				limit,
				"",
				status
			);
			if (repairs?.status) {
				setLoading(false);
				setRepair(repairs?.data?.value || []);
				return;
			}
			toast.error(repairs?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	const fetchEngineers = async () => {
		setLoading(true);
		try {
			const res = await UserService.ListUserByRole("Engineering", "", 25);
			if (res?.status) {
				setLoading(false);
				setEngineersList(res?.data?.value || []);
				return;
			}
			toast.error(res?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		if (userRole === "Technical Manager" || userRole == "Super Admin") {
			fetchEngineers();
		}
	}, [userRole]);

	useEffect(() => {
		listRepairs(searchQuery, limit, status);
	}, [searchQuery, limit, status]);

	const navigate = useNavigate();
	return (
		<>
			<div className="container__wrapper">
				<Sidebar />
				<div className="section__container">
					<div className="header__title">
						<h3>Repairs</h3>
					</div>
					<div className="top-style">
						{(userRole === "Customer Care" || userRole === "Super Admin" ) && (
							<button
								className="action-btn"
								onClick={() => {
									setRecordToEdit(null);
									setModalShow(true);
								}}
							>
								Add Repair
							</button>
						)}

						<div className="search-box">
							<input
								className="form-control"
								type="text"
								placeholder="search product"
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
					</div>
					<div className="top-style">
						<div className="filter-box">
							{[
								{ id: 1, name: "Accepted" },
								{ id: 2, name: "Under Repair" },
								{ id: 3, name: "Repaired" },
								{ id: 4, name: "Completed" },
							].map((x) => (
								<FilterBox
									key={x.id}
									name={x.name}
									onClick={() => setStatus(x.id)}
									isActive={status === x.id}
								/>
							))}
						</div>
						<div className="">
							{userRole == "Super Admin" && (
								<button
									className="action-btn blank"
									onClick={() => {
										setExportModalShow(true);
									}}
								>
									<FiDownload />
									<span>Export to excel</span>
								</button>
							)}
						</div>
					</div>
					<div className={`table-responsive ${repair?.length > 0 ? "" : "people-head"}`}>
						<Table>
							<thead>
								<tr className="table-head-row">
									<TableHead>Customer Name</TableHead>
									<TableHead>Product Name</TableHead>
									<TableHead>Serial Number</TableHead>
									<TableHead>Fault Description</TableHead>
									<TableHead>Repair Cost</TableHead>
									<TableHead>Repair Item</TableHead>
									<TableHead>Engineer Assigned</TableHead>
									<TableHead>Date In</TableHead>
									<TableHead>Date Out</TableHead>
									<TableHead>Status</TableHead>
									<TableHead>Action</TableHead>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<TableSkeleton col={5} row={11} />
								) : (
									<>
										{repair?.length > 0 ? (
											<>
												{repair.map((x) => {
													return (
														<tr className="tbody-row">
															<TableData>{x.customerName}</TableData>
															<TableData>{x.name}</TableData>
															<TableData>{x.serialNumber}</TableData>
															<TableData>{x.faultDescription}</TableData>
															<TableData>{x.repairCost}</TableData>
															<TableData>{x.repairItem}</TableData>
															<TableData>{x.engineer?.fullName}</TableData>
															<TableData>{x.dateIn}</TableData>
															<TableData>{x.dateOut}</TableData>
															<TableData>{x.status}</TableData>
															<TableData>
																<button
																	className="table-btn"
																	onClick={() => {
																		//navigate(`/product-detail/${x?.productId}`);
																		setRecordToEdit(x);
																		setIsEdit(true);
																		setModalShow(true);
																	}}
																>
																	View Repair
																</button>
															</TableData>
														</tr>
													);
												})}
											</>
										) : (
											<NoData
												text="There is no repair to show yet"
												icon={<GiAutoRepair />}
											/>
										)}
									</>
								)}
							</tbody>
						</Table>
					</div>
					<div className="d-flex justify-content-between align-items-center mt-4">
						<div className="page-count">
							Showing {repair?.length || 0} items on this page
						</div>
						<button
							className="view-more"
							onClick={() => setLimit((prev) => prev + 10)}
						>
							View More
						</button>
					</div>
				</div>
			</div>
			{modalShow && (
				<RepairModal
					show={modalShow}
					onHide={() => setModalShow(false)}
					data={recordToEdit}
					isEdit={isEdit}
					serialNumber={recordToEdit?.serialNumber}
					engineerLists={engineersList}
				/>
			)}
			{exportModalShow && (
				<ExportModal
					show={exportModalShow}
					onHide={() => setExportModalShow(false)}
					isRepair={true}
					name="Reapir"
				/>
			)}
		</>
	);
}

export default Repair;

import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Sidebar from "../component/Sidebar";
import "./style/product-detail.css";
import ProductService from "../services/ProductService";
import RepairModal from "../component/RepairModal";
import ProductModal from "../component/ProductModal";
import { useParams } from "react-router-dom";
import { UserComp } from "../component/miniComponent/UserComp";
import { NoData } from "../component/miniComponent/NoData";
import { GiAutoRepair } from "react-icons/gi";
import { TableSkeleton } from "../component/miniComponent/TableSkeleton";
import { TableHead } from "../component/miniComponent/TableHead";
import { TableData } from "../component/miniComponent/TableData";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import formatDate from "../component/miniComponent/formatDate";
import { FilterBox } from "../component/miniComponent/FilterBox";
import ExportModal from "../component/ExportModal";
import UserService from "../services/UserService";

function ProductDetail() {
	const userRole = localStorage.getItem("role");
	const userId = localStorage.getItem("id");
	const params = useParams();
	const [repair, setRepair] = useState([]);
	const [product, setProduct] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const [repairRecordToEdit, setRepairRecordToEdit] = useState(null);
	const [productRecordToEdit, setProductRecordToEdit] = useState(null);
	const [productModalShow, setProductModalShow] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [limit, setLimit] = useState(20);
	const [loading, setLoading] = useState(false);
	const [prodLoad, setProdLoad] = useState(false);

	const isEngineer = userRole && userRole === "Engineering";
	const [status, setStatus] = useState(isEngineer ? "1" : "");
	const [engineerId] = useState(isEngineer ? userId : "");
	const [isEdit, setIsEdit] = useState(false);
	const [engineersList, setEngineersList] = useState([]);

	const listReapairs = async (id, search, limit, engineerId, status) => {
		setLoading(true);
		try {
			const repairs = await ProductService.getProductRepairs(
				id,
				search,
				limit,
				engineerId,
				status
			);
			if (repairs?.status) {
				setLoading(false);
				setRepair(repairs?.data?.value || []);
				return;
			}
			toast.error(repairs?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	const fetchEngineers = async () => {
		setLoading(true);
		try {
			const res = await UserService.ListUserByRole("Engineering", "", 25);
			if (res?.status) {
				setLoading(false);
				setEngineersList(res?.data?.value || []);
				return;
			}
			toast.error(res?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	const getProduct = async (id) => {
		setProdLoad(true);
		try {
			const prod = await ProductService.getProduct(id);
			if (prod?.status) {
				setProdLoad(false);
				setProduct(prod?.data || {});
				return;
			}
			toast.error(prod?.message);
			setProdLoad(false);
		} catch (error) {
			setProdLoad(false);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		listReapairs(params.id, searchQuery, limit, engineerId, status);
		getProduct(params.id);
	}, [searchQuery, limit, params.id, engineerId, status, userRole]);

	useEffect(() => {
		if (userRole === "Technical Manager") {
			fetchEngineers();
		}
	}, [userRole]);
	return (
		<>
			<div className="container__wrapper">
				<Sidebar />
				<div className="section__container">
					<div className="header__title">
						<h3>Product Detail</h3>
					</div>
					{prodLoad ? (
						<Skeleton height="200px" />
					) : (
						<div className="product__detail">
							<div className="product-top">
								<div className="product-customer">
									<div className="circle">
										{product?.customerName
											?.split(" ")?.slice(0,2)
											?.map((name) => name.charAt(0))
											?.join("")
											?.toUpperCase()}
									</div>
									<div>
										<div className="p-name">{product.customerName}</div>
										<div className="p-sub">{formatDate(product.date)}</div>
									</div>
								</div>
								<div className="right">
									{(userRole == "Super Admin" || userRole == "Sales") && (
										<button
											className="update-btn"
											onClick={() => {
												setProductRecordToEdit(product);
												setProductModalShow(true);
											}}
										>
											Update Product
										</button>
									)}
								</div>
							</div>
							<div className="product-btm">
								<UserComp label="Product Name" name={product.name} />
								<UserComp label="Serial Number" name={product.serialNumber} />
								<UserComp
									label="Waranty(Months)"
									name={product.warantyDuration}
								/>
								<UserComp label="Waranty Status" name={product.warantyStatus} />
								{/* <UserComp label="Date" name={product.date} /> */}
							</div>
						</div>
					)}
					<div className="top-style mt-4">
						{(userRole == "Super Admin" || userRole == "Customer Care") && (
							<button
								className="action-btn blank"
								onClick={() => {
									setRepairRecordToEdit(product);
									setModalShow(true);
								}}
							>
								Add Repair
							</button>
						)}
						<div className="search-box">
							<input
								className="form-control"
								type="text"
								placeholder="search repair"
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
					</div>
					<div className="top-style">
						<div className="filter-box">
							{/* {userRole == "Engineering" && (
								<FilterBox
									name={"My Repairs"}
									onClick={() => setEngineerId(engineerId ? "" : userId)}
									isActive={engineerId === userId}
								/>
							)} */}
							{[
								{ id: 1, name: "Accepted" },
								{ id: 2, name: "Under Repair" },
								{ id: 3, name: "Repaired" },
								{ id: 4, name: "Completed" },
							].map((x) => (
								<FilterBox
									key={x.id}
									name={x.name}
									onClick={() => setStatus(x.id)}
									isActive={status === x.id}
								/>
							))}
						</div>
					</div>
					<div className={`table-responsive ${repair?.length > 0 ? "" : "people-head"}`}>
						<Table>
							<thead>
								<tr className="table-head-row">
									<TableHead>Fault Description</TableHead>
									<TableHead>Repair Cost</TableHead>
									<TableHead>Repair Item</TableHead>
									<TableHead>Date In</TableHead>
									<TableHead>Date Out</TableHead>
									<TableHead>Status</TableHead>
									{userRole !== "Sales" && <TableHead>Action</TableHead>}
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<TableSkeleton col={5} row={userRole == "Sales" ? 6 : 7} />
								) : (
									<>
										{repair?.length > 0 ? (
											<>
												{repair.map((x) => {
													return (
														<tr className="tbody-row">
															<TableData>{x.faultDescription}</TableData>
															<TableData>{x.repairCost}</TableData>
															<TableData>{x.repairItem}</TableData>
															<TableData>{x.dateIn}</TableData>
															<TableData>{x.dateOut}</TableData>
															<TableData>{x.status}</TableData>
															{userRole !== "Sales" && (
																<TableData>
																	<button
																		className="table-btn"
																		onClick={() => {
																			setRepairRecordToEdit(x);
																			setIsEdit(true);
																			setModalShow(true);
																		}}
																	>
																		Update Repair
																	</button>
																</TableData>
															)}
														</tr>
													);
												})}
											</>
										) : (
											<NoData
												text="There is no repair to show yet"
												icon={<GiAutoRepair />}
											/>
										)}
									</>
								)}
							</tbody>
						</Table>
					</div>
					<div className="d-flex justify-content-between align-items-center mt-4">
						<div className="page-count">
							Showing {repair?.length || 0} items on this page
						</div>
						<button
							className="action-btn blank"
							onClick={() => setLimit((prev) => prev + 5)}
						>
							View More
						</button>
					</div>
				</div>
			</div>
			{modalShow && (
				<RepairModal
					show={modalShow}
					onHide={() => setModalShow(false)}
					data={repairRecordToEdit}
					serialNumber={product.serialNumber}
					isEdit={isEdit}
					engineerLists={engineersList}
				/>
			)}
			{productModalShow && (
				<ProductModal
					show={productModalShow}
					onHide={() => setProductModalShow(false)}
					data={product}
				/>
			)}
		</>
	);
}

export default ProductDetail;

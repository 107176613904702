import React from "react";

export const UserComp = ({ name, label }) => {
	return (
		<div className="user-sec">
			<div className="user-t">{label}</div>
			<div className="user-m">{name}</div>
		</div>
	);
};

import React from "react";

export const NoData = ({ text, icon }) => {
	return (
		<div className="data-box">
			<div className="nodata-icon">{icon}</div>
			<div className="nodata-text">{text}</div>
		</div>
	);
};

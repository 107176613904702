import React, { useEffect, useState } from "react";
import "./style/style.css";
import Sidebar from "../component/Sidebar";
import Metric from "../component/Metric";
import { Table } from "react-bootstrap";
import "./style/dashboard.css";
import ProductService from "../services/ProductService";
import { TableHead } from "../component/miniComponent/TableHead";
import { TableData } from "../component/miniComponent/TableData";
import { FiActivity } from "react-icons/fi";
import { toast } from "react-toastify";
import { TableSkeleton } from "../component/miniComponent/TableSkeleton";
import { NoData } from "../component/miniComponent/NoData";
import { FaPeopleRoof, FaUser } from "react-icons/fa6";
import { GiAutoRepair } from "react-icons/gi";

function Dashboard() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const getDashboardMetrics = async () => {
		setLoading(true);
		try {
			const metrics = await ProductService.getDashboardmetrics();
			if (metrics?.status) {
				setLoading(false);
				const data = metrics?.data;
				setData(data);
				return;
			}
			toast.error(metrics?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		getDashboardMetrics();
	}, []);

	console.log({ data });

	return (
		<div className="container__wrapper">
			<Sidebar />
			<div className="section__container">
				<div className="header__title">
					<h3>Dashboard</h3>
				</div>
				<div className="card-grid">
					<Metric
						data={data?.productsCount || 0}
						title="Total number of product(s)"
						isLoading={loading}
					/>
					<Metric
						data={data?.productUnderWaranty || 0}
						title="Total number product(s) under waranty."
						isLoading={loading}
					/>

					<Metric
						data={data?.productNotUnderWaranty || 0}
						title="Total number product(s) not under waranty."
						isLoading={loading}
					/>
				</div>
				<div className="row row__style">
					<div className="col-md-12 col__style">
						<h5 className="section__title mt-5">Recent Product</h5>
						<div className="table-responsive dash-head">
							<Table>
								<thead>
									<tr className="table-head-row">
										<TableHead>Product Name</TableHead>
										<TableHead>Customer Name</TableHead>
										<TableHead>Serial Number</TableHead>
										<TableHead>Waranty Duration(Months)</TableHead>
										<TableHead>Waranty Status</TableHead>
									</tr>
								</thead>
								<tbody>
									{loading ? (
										<TableSkeleton col={5} row={5} />
									) : (
										<>
											{data?.products?.length > 0 ? (
												<>
													{data?.products?.slice(0, 5)?.map((x) => {
														return (
															<tr className="tbody-row">
																<TableData>{x.name}</TableData>
																<TableData>{x.customerName}</TableData>
																<TableData>{x.serialNumber}</TableData>
																<TableData>{x.warantyDuration}</TableData>
																<TableData>{x.warantyStatus}</TableData>
															</tr>
														);
													})}
												</>
											) : (
												<NoData
													text="There is no product to show yet"
													icon={<FaPeopleRoof />}
												/>
											)}
										</>
									)}
								</tbody>
							</Table>
						</div>
					</div>
				</div>
				{/* <div className='row row__style'>
                <div className='col-md-12 col__style'>
                    <h5 className='section__title'>Recent Repairs</h5>
                    <Table striped>
                        <thead>
                            <tr>
                            <th>Product Name</th>
                            <th>Serial Number</th>
                            <th>Fault</th>
                            <th>Repair Item</th>
                            <th>Repair Cost</th>
                            <th>Date In</th>
                            <th>Date Out</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data?.repairs?.map(x => {
                                return (
                                    <tr>
                                        <td>{x?.product?.name}</td>
                                        <td>{x.serialNumber}</td>
                                        <td>{x.faultDescription}</td>
                                        <td>{x.repairItem}</td>
                                        <td>{x.repairCost}</td>
                                        <td>{x.dateIn}</td>
                                        <td>{x.dateOut}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </div> */}
				<div className="row row__style">
					<div className="col-md-12 col__style">
						<h5 className="section__title">Recent Product Repairs</h5>
						<div className="table-responsive table-responsive dash-head">
							<Table>
								<thead>
									<tr className="table-head-row">
										<TableHead>Customer Name</TableHead>
										<TableHead>Product Name</TableHead>
										<TableHead>Serial Number</TableHead>
										<TableHead>Fault</TableHead>
										<TableHead>Repair Item</TableHead>
										<TableHead>Repair Cost</TableHead>
										<TableHead>Date In</TableHead>
										<TableHead>Date Out</TableHead>
									</tr>
								</thead>
								<tbody>
									{loading ? (
										<TableSkeleton col={5} row={8} />
									) : (
										<>
											{data?.productRepairs?.length > 0 ? (
												<>
													{data?.productRepairs?.slice(0, 5)?.map((x) => {
														return (
															<tr className="tbody-row">
																<TableData>{x.customerName}</TableData>
																<TableData>{x.name}</TableData>
																<TableData>{x.serialNumber}</TableData>
																<TableData>
																	{x.faulTableDataescription}
																</TableData>
																<TableData>{x.repairItem}</TableData>
																<TableData>{x.repairCost}</TableData>
																<TableData>{x.dateIn}</TableData>
																<TableData>{x.dateOut}</TableData>
															</tr>
														);
													})}
												</>
											) : (
												<NoData
													text="There is no repair to show yet"
													icon={<GiAutoRepair />}
												/>
											)}
										</>
									)}
								</tbody>
							</Table>
						</div>
					</div>
				</div>
				<div className="card-grid-2 mb-4">
					<div className="w-100">
						<h5 className="section__title">Recent Activity</h5>
						<div className="activity">
							{data?.activity?.length > 0 ? (
								<div className="act-box">
									{data?.activity?.slice(0, 4)?.map((x) => {
										return (
											<div
												className={`activity-box ${
													x?.message?.split(" ")?.pop() === "updated"
														? "update"
														: x?.message?.split(" ")?.pop() === "repairs"
														? "repair"
														: "added"
												}`}
											>
												{x.message}
											</div>
										);
									})}
								</div>
							) : (
								<div className="no-activity">
									<div className="no-activity-icon">
										<FiActivity />
									</div>
									<div className="no-activity-title">
										No recent activity to show!
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="w-100">
						<h5 className="section__title">Recent Users</h5>
						<Table>
							<thead>
								<tr className="table-head-row">
									<TableHead>Fullname</TableHead>
									<TableHead>Role</TableHead>
									<TableHead>Active</TableHead>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<TableSkeleton col={5} row={3} />
								) : (
									<>
										{data?.users?.length > 0 ? (
											<>
												{data?.users?.slice(0, 5)?.map((x) => {
													return (
														<tr className="tbody-row">
															<TableData>{x?.fullName}</TableData>
															<TableData>{x.role}</TableData>
															<TableData>
																{x.isActive ? "True" : "False"}
															</TableData>
														</tr>
													);
												})}
											</>
										) : (
											<NoData
												text="There is no user to show yet"
												icon={<FaUser />}
											/>
										)}
									</>
								)}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;

import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import UserService from '../services/UserService';

function ResetPassword() {
  const [payload, setPayload] = useState({password: "", confirmPassword: ""});
  const params = useParams();
  const navigate = useNavigate();

  const updatePayload=(e)=>{
    const name = e.target.name;
    setPayload(prevState => ({
      ...prevState,
      [name]: e.target.value
    })); 
  }

  const passwordReset = async() => {
    
    payload.code = params.code;
    if(payload.password !== payload.confirmPassword){
      alert("Your passwords are not equal, Try again");
      return
    }
    let resetPayload = {code: payload.code, newPassword: payload.password}
    const response = await UserService.PasswordResetComplete(resetPayload);
    if(response?.status){
        alert(response?.message || "Password reset succesful");
        navigate('/');
    }else{
      alert("An error occured");
    }
  }
  return (
    <div className='main__container'>
        <div className='login__container'>
            <div className='login__input'>
                <h2>Set New Password</h2>
                <input className='form-control input__field' type="password" placeholder="Password" name="password" value={payload.password} onChange={updatePayload} required/>
                <input className='form-control input__field' type="password" placeholder="Confirm Password" name="confirmPassword" value={payload.confirmPassword} onChange={updatePayload} required/>
                <button className="btn btn-warning btn-block" onClick={passwordReset}>Confirm</button>
            </div>
        </div> 
    </div>
  )
}

export default ResetPassword
import React, { useState } from 'react'
import formatDate from './miniComponent/formatDate';
import { Modal } from 'react-bootstrap';
import { PrimaryInput } from './miniComponent/PrimaryInput';
import { PropagateLoader } from 'react-spinners';

function ExportModal(props) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");

    const url = props.isRepair ? `${process.env.REACT_APP_API}/api/repair/export?startDate=${startDate}&endDate=${endDate}` : 
                    `${process.env.REACT_APP_API}/api/product/export?startDate=${startDate}&endDate=${endDate}`;

    const filename = `${props.name} Report for ${startDate && endDate ? formatDate(startDate) + ' - ' + formatDate(endDate) : 'all time'}`;
    const ExportRecord = async () => {
        try 
        {
            setLoading(true);
    
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = filename;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                console.error('Error exporting data:', response.statusText);
            }
        } catch (error) {
            console.error('Error exporting data:', error);
        } finally {
            setLoading(false);
        }      
    }
  return (
    <Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props?.isRepair ? "Export Repair" : "Export Product"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal__main_container">
					<div className="card-grid-2 mt-3">
						<PrimaryInput
							defaultValue={props?.data?.dateIn}
							onChange={(e) => setStartDate(e.target.value)}
							label="Date From"
							type="date"
						/>
						<PrimaryInput
							defaultValue={props?.data?.dateOut}
							onChange={(e) => setEndDate(e.target.value)}
							label="Date To"
							type="date"
						/>
						
					</div>
				</div>
			</Modal.Body>
			<div className="modal-foot">
				<button onClick={props.onHide} className="btn-form outline">
					Close
				</button>
				<button
					className="btn-form fill"
					onClick={() => {
						ExportRecord();
					}}
				>
					{loading ? (
						<PropagateLoader color={"white"} loading size={8} />
					) : (
						"Export"
					)}
				</button>
			</div>
		</Modal>
  )
}

export default ExportModal
import axios from 'axios';
import Toastr from './Toastr';

export default async function APICall(
    Url,
    Method = "GET",
    Data = null,
    timeoutOverride,
    silent,
    useCache = false
) {
    Method = Method || "GET";
    const cacheKey = Url.toLowerCase();
    if (useCache && Method.toUpperCase() == "GET") {
      const cachedResponse = sessionStorage.getItem(cacheKey);
      if (cachedResponse) return JSON.parse(cachedResponse);
    } else {
      sessionStorage.removeItem(cacheKey);
    }
  
    if (localStorage.getItem("token")) {
      const authToken = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;
    }
  
    axios.defaults.withCredentials = true;
    axios.interceptors.response.use(
      (response) => {
        if (response?.data?.authorization) {
          localStorage.setItem("token", response.data.authorization);
        }
        return response;
      },
      (error) => {
        return error.response;
      }
    );
  
    let baseUrl = process.env.REACT_APP_API;

    if (!baseUrl.endsWith("/")) {
      baseUrl = baseUrl + "/";
    }
  
    if (Url.startsWith("/")) {
      Url = Url.substring(1);
    }
  
    const new_request = {
      method: Method,
      url: baseUrl + Url,
      data: Data,
    };
  
    let response = await axios(new_request);
  
    try {
    } catch (error) {}
  
    if (response) {
      if (!response.status || response.status === 0) {
        if (!silent)
          Toastr(
            "error",
            "Sorry it seems you are not connected to internet. Please check you network connection and try again"
          );
        return null;
      }
      if (response.status === 401 || response.statusText === "Unauthorized") {
        localStorage.clear();
        window.location.href = "/";
        return null;
      }
      if (response.status >= 400 && response.status < 500) {
        let message = "Your request generated an error. Please review and retry";
        if (response.data) {
          if (response.data.title) {
            message = `${response.data.title}. ${response.data.details ?? ""}`;
          } else {
            message = response.data;
          }
        }
        if (!silent) Toastr("warning", message);
        return response?.data;
      }
      if (response.status >= 500) {
        let message =
          "Sorry your request cannot be processed at this moment please try again later";
        if (response.data) {
          message = `${response.data.title}. ${response.data?.details}`;
        }
        if (!silent) Toastr("error", message);
        return null;
      }
    } else {
      if (!silent) {
        Toastr(
          "error",
          "Your request generated an error. Please check your network connection"
        );
      }
    }
  
    if (useCache && Method.toUpperCase() == "GET" && response?.data) {
      sessionStorage.setItem(cacheKey, JSON.stringify(response.data));
    }
  
    return !response ? null : (response.data  || response.data === 0 || response.data === "0" || response.data === false) ? response.data : { status: "success" };
}
  
import React, { useEffect, useState } from "react";
// import ProductmaintainanceModal from "../component/ProductmaintainanceModal";
import Sidebar from "../component/Sidebar";
import { Table } from "react-bootstrap";
import ProductService from "../services/ProductService";
import { NoData } from "../component/miniComponent/NoData";
import { TableSkeleton } from "../component/miniComponent/TableSkeleton";
import { TableHead } from "../component/miniComponent/TableHead";
import { TableData } from "../component/miniComponent/TableData";
import { toast } from "react-toastify";
import { FilterBox } from "../component/miniComponent/FilterBox";
import ExportModal from "../component/ExportModal";
import { FiDownload } from "react-icons/fi";
import { GrInstall } from "react-icons/gr";
import InstallationModal from "../component/InstallationModal";
import UserService from "../services/UserService";
import MaintainanceModal from "../component/MaintainanceModal";

function Maintainance() {
	const userRole = localStorage.getItem("role");
	const isEngineer = userRole && userRole === "Engineering";
	const userId = localStorage.getItem("id");

	const [maintainance, setMaintainance] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [limit, setLimit] = useState(20);
	const [loading, setLoading] = useState(false);
	const [isComplete] = useState(
		userRole == "Store" || userRole == "Technical Manager" ? false : ""
	);
	const [status, setStatus] = useState("");
	const [engineerId] = useState(isEngineer ? userId : "");
	const [exportModalShow, setExportModalShow] = useState(false);
	const [engineersList, setEngineersList] = useState([]);

	const listmaintainance = async (
		search,
		limit,
		engineerId,
		status,
		isComplete
	) => {
		setLoading(true);
		try {
			const maintainances = await ProductService.getAllMaintainances(
				search,
				limit,
				engineerId,
				status,
				isComplete
			);
			if (maintainances?.status) {
				setLoading(false);
				setMaintainance(maintainances?.data?.value || []);
				return;
			}
			toast.error(maintainances?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};
	const fetchEngineers = async () => {
		setLoading(true);
		try {
			const res = await UserService.ListUserByRole("Engineering", "", 25);
			if (res?.status) {
				setLoading(false);
				setEngineersList(res?.data?.value || []);
				return;
			}
			toast.error(res?.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		listmaintainance(searchQuery, limit, engineerId, status, isComplete);
	}, [searchQuery, limit, status, engineerId, isComplete]);

	useEffect(() => {
		if (userRole === "Technical Manager") {
			fetchEngineers();
		}
	}, [userRole]);
	return (
		<>
			<div className="container__wrapper">
				<Sidebar />
				<div className="section__container">
					<div className="header__title">
						<h3>Maintainance</h3>
					</div>
					<div className="top-style">
						{(userRole == "Customer Care" || userRole == "Super Admin") && (
							<button
								className="action-btn"
								onClick={() => {
									setRecordToEdit(null);
									setModalShow(true);
								}}
							>
								Add Maintainance
							</button>
						)}

						<div className="search-box">
							<input
								className="form-control"
								type="text"
								placeholder="search maintainance"
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
					</div>
					<div className="top-style">
						<div className="filter-box">
							{[
								{ id: "", name: "All" },
								{ id: 1, name: "Not Started" },
								{ id: 2, name: "Contacted" },
								{ id: 3, name: "In Progress" },
								{ id: 4, name: "Completed" },
							].map((x) => (
								<FilterBox
									key={x.id}
									name={x.name}
									onClick={() => setStatus(x.id)}
									isActive={status === x.id}
								/>
							))}
						</div>
						<div className="">
							{userRole == "Super Admin" && (
								<button
									className="action-btn blank"
									onClick={() => {
										setExportModalShow(true);
									}}
								>
									<FiDownload />
									<span>Export to excel</span>
								</button>
							)}
						</div>
					</div>
					<div
						className={`${
							maintainance?.length > 0 ? "table-responsive" : "people-head"
						}`}
					>
						<Table>
							<thead>
								<tr className="table-head-row">
									<TableHead>Customer Name</TableHead>
									<TableHead>Customer Address</TableHead>
									<TableHead>Phone Number</TableHead>
									<TableHead>Product Name</TableHead>
									<TableHead>Scheduled Date Of Installation</TableHead>
									{userRole != "Engineering" && (
										<TableHead>Engineer Assigned</TableHead>
									)}
									<TableHead>maintainance Cost</TableHead>
									<TableHead>Status</TableHead>
									<TableHead>Action</TableHead>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<TableSkeleton col={5} row={10} />
								) : (
									<>
										{maintainance?.length > 0 ? (
											<>
												{maintainance.map((x) => {
													return (
														<tr className="tbody-row">
															<TableData>{x.customerName}</TableData>
															<TableData>{x.customerAddress}</TableData>
															<TableData>{x.customerPhone}</TableData>
															<TableData>{x.productToInstall}</TableData>
															<TableData>{x.dateOfInstallation.split("T")?.at(0)}</TableData>
															{userRole != "Engineering" && (
																<TableData>{x.engineer?.fullName}</TableData>
															)}
															<TableData>{x.costOfInstallation}</TableData>
															<TableData>{x.status}</TableData>
															<TableData>
																<button
																	className="table-btn"
																	onClick={() => {
																		setRecordToEdit(x);
																		setIsEdit(true);
																		setModalShow(true);
																	}}
																>
																	View maintainance
																</button>
															</TableData>
														</tr>
													);
												})}
											</>
										) : (
											<NoData
												text="There is no maintainance to show yet"
												icon={<GrInstall />}
											/>
										)}
									</>
								)}
							</tbody>
						</Table>
					</div>
					<div className="d-flex justify-content-between align-items-center mt-4">
						<div className="page-count">
							Showing {maintainance?.length || 0} items on this page
						</div>
						<button
							className="view-more"
							onClick={() => setLimit((prev) => prev + 10)}
						>
							View More
						</button>
					</div>
				</div>
			</div>
			{modalShow && (
				<MaintainanceModal
					show={modalShow}
					onHide={() => setModalShow(false)}
					data={recordToEdit}
					isEdit={isEdit}
					engineerLists={engineersList}
				/>
			)}
			{exportModalShow && (
				<ExportModal
					show={exportModalShow}
					onHide={() => setExportModalShow(false)}
					ismaintainance={true}
					name="Reapir"
				/>
			)}
		</>
	);
}

export default Maintainance;

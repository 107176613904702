import { ToastContainer } from "react-toastify";
import "./App.css";
import RouteLinks from "./RouteLinks";

function App() {
	return (
		<div>
			<ToastContainer />
			<RouteLinks />
		</div>
	);
}

export default App;

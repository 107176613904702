import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ProductService from "../services/ProductService";
import { PrimaryInput } from "./miniComponent/PrimaryInput";
import CustomCheckbox from "./miniComponent/CustomCheckBox";
import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";
import { PrimarySelect } from "./miniComponent/PrimarySelect";

function RepairModal(props) {
	const [payload, setPayload] = useState(props?.data);
	const [loading, setLoading] = useState(false);
	const [collect, setCollect] = useState(
		props?.data?.status == "collected" || false
	);
	const updatePayload = (data, key) => {
		const tempPayload = { ...payload };
		tempPayload[key] = data;
		setPayload(tempPayload);
	};

	let data = props.data;
	const userRole = localStorage.getItem("role");
	console.log({ props });

	const updateData = {
		id: props?.data?.id,
		pcbSerialNumber: props?.data?.pcbSerialNumber,
		faultDescription: props?.data?.faultDescription,
		repairItem: props?.data?.repairItem,
		repairCost: props?.data?.repairCost,
		dateIn: props?.data?.dateIn,
		dateOut: props?.data?.dateOut,
		status: props?.data?.status,
		customerName: props?.data?.customerName,
		name: props?.data?.name,
		serialNumber: props?.data?.serialNumber,
		engineerId: props?.data?.engineerId,
		comment: props?.data?.comment,
	};

	const UpdateRecord = async () => {
		if (payload?.pcbSerialNumber) {
			updateData.pcbSerialNumber = payload.pcbSerialNumber;
		}

		if (payload?.faultDescription) {
			updateData.faultDescription = payload?.faultDescription;
		}

		if (payload?.repairItem) {
			updateData.repairItem = payload?.repairItem;
		}

		if (payload?.repairCost) {
			updateData.repairCost = payload?.repairCost;
		}
		if (payload?.dateIn) {
			updateData.dateIn = payload?.dateIn;
		}

		if (payload?.dateOut) {
			updateData.dateOut = payload?.dateOut;
		}
		if (payload?.status) {
			updateData.status = payload?.status;
		}
		if (payload?.comment) {
			updateData.comment = payload?.comment;
		}
		if (payload?.engineerId) {
			updateData.engineerId = payload?.engineerId;
		}
		if (
			(userRole == "Customer Care" || userRole == "Super Admin") &&
			data.status != "Accepted"
		) {
			updateData.status = collect ? "Completed" : payload?.status;
		}
		if (
			(userRole == "Customer Care" || userRole == "Super Admin") &&
			updateData.status == "Completed" &&
			updateData.dateOut == undefined
		) {
			toast.error("Date out required");
			return;
		}

		setLoading(true);
		try {
			const response = await ProductService.UpdateProductRepair(updateData);
			if (response?.status) {
				toast.success("Successfully updated");
				setLoading(false);
				window.location.reload();
				return;
			}
			setLoading(false);
			toast.error(response?.message);
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	const CreateRecord = async () => {
		// if (!payload.pcbSerialNumber && userRole == "Engineering") {
		// 	toast.error("Pcb Serial number is Reqired");
		// 	return;
		// }
		payload.id = null;
		if (!payload.faultDescription && userRole == "Customer Care") {
			toast.error("Fault description is Reqired");
			return;
		}

		if (!payload.repairItem && userRole == "Store") {
			toast.error("Repair item is Reqired");
			return;
		}

		if (!payload.repairCost && userRole == "Customer Care") {
			toast.error("Repair cost is Reqired");
			return;
		}

		if (!payload.dateIn && userRole == "Customer Care") {
			toast.error("Date In is required");
			return;
		}

		// if (!payload.dateOut && userRole == "Customer Care") {
		// 	toast.error("Date Out is required");
		// 	return;
		// }
		if(props?.isEdit == true){
			payload.serialNumber = props?.serialNumber;
		}else{
			if (!payload.serialNumber && userRole == "Customer Care") {
				toast.error("Serial number is required");
				return;
			}
		}
		
		payload.customerName = props?.data?.customerName;
		payload.name = props?.data?.name;
		// if (!payload.status && userRole == "Customer Care") {
		// 	toast.error("Kindly confirm this order has been collected");
		// 	return;
		// }

		payload.status = collect;

		setLoading(true);
		try {
			const response = await ProductService.CreateProductRepair(payload);
			if (response?.status) {
				toast.success("Successfully created");
				setLoading(false);
				window.location.reload();
				return;
			}
			setLoading(false);
			toast.error("An error occured");
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props?.isEdit ? "Update Repair" : "Add Repair"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal__main_container">
					<div className="card-grid-2 mt-3">
						<PrimaryInput
							defaultValue={props?.data?.serialNumber}
							onChange={(e) =>
								updatePayload(e.target.value, "serialNumber")
							}
							label="Serial Number"
							disabled={
								userRole !== "Customer Care" && userRole !== "Super Admin"
							}
						/>
						<PrimaryInput
							defaultValue={props?.data?.faultDescription}
							onChange={(e) =>
								updatePayload(e.target.value, "faultDescription")
							}
							label="Fault Description"
							disabled={
								userRole !== "Customer Care" && userRole !== "Super Admin"
							}
						/>
						{(userRole == "Store" || userRole == "Super Admin") && (
							<PrimaryInput
								defaultValue={props?.data?.repairItem}
								onChange={(e) => updatePayload(e.target.value, "repairItem")}
								label="Repair Item"
								disabled={
									userRole !== "Store" && userRole !== "Super Admin"
								}
							/>
						)}
						<PrimaryInput
							defaultValue={props?.data?.repairCost}
							onChange={(e) => updatePayload(e.target.value, "repairCost")}
							label="Cost Of Repair"
							disabled={
								userRole !== "Customer Care" && userRole !== "Super Admin"
							}
						/>
						{(userRole == "Engineering" || userRole == "Super Admin") && (
							<PrimaryInput
								defaultValue={props?.data?.pcbSerialNumber}
								onChange={(e) =>
									updatePayload(e.target.value, "pcbSerialNumber")
								}
								label="PCB serial number"
								disabled={
									userRole !== "Engineering" && userRole !== "Super Admin"
								}
							/>
						)}
						<PrimaryInput
							defaultValue={props?.data?.dateIn}
							onChange={(e) => updatePayload(e.target.value, "dateIn")}
							label="Date In"
							type="date"
							disabled={
								userRole !== "Customer Care" && userRole !== "Super Admin"
							}
						/>
						<PrimaryInput
							defaultValue={props?.data?.dateOut}
							onChange={(e) => updatePayload(e.target.value, "dateOut")}
							label="Date Out"
							type="date"
							disabled={
								userRole !== "Customer Care" && userRole !== "Super Admin"
							}
						/>
						{(data != null || userRole == "Engineering" || userRole == "Super Admin") && (
							<PrimarySelect
								value={payload?.status}
								onChange={(e) => updatePayload(e.target.value, "status")}
								label="Repair status"
								options={
									<>
										{!props?.data?.status && <option>Choose a status</option>}
										{["Accepted", "Under Repair", "Repaired"].map((x) => (
											<option value={x} key={x}>
												{x}
											</option>
										))}
									</>
								}
								disabled={
									userRole !== "Engineering" && userRole !== "Super Admin"
								}
							/>
						)}
						{(userRole == "Technical Manager" || userRole == "Super Admin") && (
							<PrimarySelect
								value={payload?.engineerId}
								onChange={(e) => updatePayload(e.target.value, "engineerId")}
								label="Assign Engineer"
								options={
									<>
										{!props?.data?.engineerId && (
											<option>Choose an engineer</option>
										)}
										{props?.engineerLists?.map((x) => (
											<option value={x.id} key={x.id}>
												{x.fullName}
											</option>
										))}
									</>
								}
								disabled={
									userRole !== "Technical Manager" && userRole !== "Super Admin"
								}
							/>
						)}
					</div>
					<div className="mt-3">
						{(userRole == "Engineering" || userRole == "Super Admin") && (
							<PrimaryInput
								defaultValue={props?.data?.comment}
								onChange={(e) => updatePayload(e.target.value, "comment")}
								label="Add a Comment"
								disabled={
									userRole !== "Engineering" && userRole !== "Super Admin"
								}
								isTextArea
							/>
						)}
					</div>
				</div>
				{(userRole === "Customer Care" || userRole == "Super Admin") && (
					<div className="status mt-3">
						<CustomCheckbox
							label={"This item has been collected"}
							checked={collect}
							onChange={(e) => setCollect(!collect)}
						/>
					</div>
				)}
			</Modal.Body>
			<div className="modal-foot">
				<button onClick={props.onHide} className="btn-form outline">
					Close
				</button>
				<button
					className="btn-form fill"
					onClick={() => {
						props?.isEdit ? UpdateRecord() : CreateRecord();
					}}
				>
					{loading ? (
						<PropagateLoader color={"white"} loading size={8} />
					) : (
						"Save"
					)}
				</button>
			</div>
		</Modal>
	);
}

export default RepairModal;

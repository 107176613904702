import React from "react";
import { ClipLoader } from "react-spinners";

function Metric({ data, title, isLoading }) {
	return (
		<div className="card-child">
			{isLoading ? (
				<ClipLoader loading={true} size={40} />
			) : (
				<div className="card-title">{data}</div>
			)}
			<div className="card-sec">{title}</div>
		</div>
	);
}

export default Metric;
